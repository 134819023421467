.BuyBASEBLP {
    /* background: #080b27; */
    justify-content: space-between;
}

.BuyBASEBLP-container {
    padding: 3rem 0 2rem 0;
    width: 100%;
}

.BuyBASEBLP-container .section-title-content {
    justify-content: flex-start;
}

@media (max-width: 425px) {
    .BuyBASEBLP-container {
        padding-left: 16px!important;
        padding-right: 16px!important;
    }
}
.buypage-container-pd{
    padding-left: 16px!important;
    padding-right: 16px!important;
}

.default-btnhome.read-more {
    color: #449eff !important;
    border: 1px solid #449eff !important;
    background: none !important;
    border-radius: 10px !important;
    transition-timing-function: linear;
    transition-property: all;
    transition-delay: 150ms;
}

.default-btnhome.read-more:hover {
    color: #0029e6 !important;
    border: 1px solid #0029e6 !important;
    background: none !important;  }

.default-btnhome {
    border-radius: 10px !important;
  }