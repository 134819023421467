.SellBlp-content {
  display: grid;
  grid-template-columns: 1fr 28rem;
  grid-gap: 1rem;
  margin-top: 0.5rem;
  padding: 2rem;
  padding-top: 1rem;
}

.SellBlp-box {
  padding: 1rem;
}

.SellBlp-cta {
  margin-top: 0.5rem;
}


@media (max-width: 900px) {
  .SellBlp-content {
    grid-template-columns: 1fr;
  }
}
