.FarmBlp .Page-title-section {
  position: relative;
  z-index: 2;
}

.FarmBlp{
  padding-top: 3rem;
}

.VesterDepositModal-info-rows {
  margin-bottom: 0.5rem;
}

.CompoundModal-menu {
  margin-bottom: 0.5rem;
}

.CompoundModal-menu .Checkbox {
  margin-bottom: 0.3rem;
}

.FarmBlp-address-input {
  padding: 1rem 2.2rem;
  padding-bottom: 0;
}

.FarmBlp-buy-base-modal .Modal-content {
  max-width: 30rem;
}

.FarmBlp-address-input input {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.1rem;
}

.FarmBlp-boost-bar {
  overflow: hidden;
  vertical-align: middle;
  margin-left: 0.2rem;
  border-radius: 2px;
  width: 1rem;
  height: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  position: relative;
}

.FarmBlp-boost-icon {
  font-size: 0.7rem;
  z-index: 2;
}

.FarmBlp-boost-bar-fill {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #b6b9cc;
}

.FarmBlp-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 0.5rem;
  padding-top: 1rem;
  margin-bottom:70px;
}

.FarmBlp-content {
}

@media (max-width: 900px) {
  .FarmBlp-cards {
    grid-template-columns: 1fr;
  }

  .FarmBlp-content {
    min-height: 100vh;
  }

  .FarmBlp-total-rewards-card {
    grid-row: 4;
  }
}
.ahreftextcolor{
  color: white;
}
.ahreftextcolor:hover{
  color: white;
}
.btnwt{
  width: 35%;
}
@media (max-width: 900px) {
  .btnwt{
    width: 38%;
  }
}
@media (max-width: 425px) {
  .btnwt{
    width: 46%;
  }
}
@media (max-width: 366px) {
  .btnwt{
    width: 53%;
  }
}
.mb-6{
  margin-bottom: 42px;
}
.marginbottommedia{
  margin-bottom: -20px;
}
@media (max-width: 767px) {
  .marginbottommedia{
    margin-bottom: 10px;
  }
}


 
