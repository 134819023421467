.Modal {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.Modal-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
}

.Modal-content {
  z-index: 20;
  position: relative;
  max-width: 100%;
  max-height: 90vh;
  overflow: auto;
  /* background: #080b27;
  border-radius: 15px; */
  /* border: 1px solid rgb(28 36 75); */
  border-radius: 20px;
  background: #1b1e29;
}

.divider {
  border-bottom: 1px solid #ffffff29;
  margin-bottom: 1rem;
}

.Modal.non-scrollable .Modal-content {
  overflow: visible;
}

.Modal-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}
.Modal-body {
  margin: 1rem;
  margin-bottom: 1.5rem;
}
.Modal-body::-webkit-scrollbar {
  width: 6px;
}

.Modal-body::-webkit-scrollbar-track {
  background-color: #1c1c1c;
  border-radius: 100rem;
}

.Modal-body::-webkit-scrollbar-thumb {
  background-color: #949393;
  border-radius: 100rem;
}

.Modal-title {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
}

.Modal-close-button {
  text-align: right;
}

.Modal-close-icon {
  opacity: 0.6;
  cursor: pointer;
  text-align: right;
  display: inline-block;
}

.Modal-close-icon:hover {
  opacity: 0.9;
}

.Modal-note {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
