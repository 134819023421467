html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  font-family: 'Inter', Consolas;
  letter-spacing: 0.5px;
  font-size: 15.5px;
  background-color: #01011b;
}

* {
  box-sizing: border-box;
  font-family: 'Inter', Consolas;
  font-weight:300;
  transition: all 0.1s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  overflow: auto;
}
body{
  overflow-y: scroll
}

h1,h2,h3,h4,h5,h6{
  line-height: normal!important;
    font-family: 'Inter',sans-serif!important;
    font-weight: 500!important;
    letter-spacing: 1.5px;
}

p{
  font-family: Inter,sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  /* background-color: #ebebeb; */
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(50, 53, 92);
}

:root {
  --dark-blue: #433c00;
  --dark-blue-bg: #16182e;
  --dark-blue-accent: #c4b9a0;
  --dark-blue-hover: #808aff14;
  --dark-blue-border: #ffffff29;
  --dark-blue-active: #818cef33;
  --border-radius-sm: 4px;
}

a {
  color: white;
}
a:hover{
  color: black;
}

.Card-title {
  font-size: 2rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: 1.3rem;
  color: white;
  outline: none;
  background: none;
  border: none;
  padding: 0.8rem;
  font-family: 'Inter', Consolas;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

button {
  outline: none;
  cursor: pointer;
  font-family: 'Inter', Consolas;
}

button:disabled,
button.App-cta:disabled {
  cursor: not-allowed;
  opacity: 0.9;
  box-shadow: none;
}

table {
  margin: 0;
  padding: 0;
}

tr {
  margin: 0;
  padding: 0;
}

td {
  margin: 0;
  padding: 0;
  padding-right: 0.3rem;
}

.border {
  border: 1px solid rgba(163, 165, 196, 0.2);
}

.align-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.muted {
  opacity: 0.7;
}

.strong {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.text-none {
  text-transform: none;
}

.margin-bottom {
  margin-bottom: 0.3rem;
}

.transition-arrow {
  vertical-align: middle;
  margin-top: -0.2rem;
}

.inline-block {
  display: inline-block;
}

.label,
.label a {
  color: #b6b9cc;
}

ul {
  padding-left: 1.5rem;
  margin-bottom: 0;
}

ul li {
  padding-bottom: 1rem;
}

a.plain {
  text-decoration: none;
}
a.plain:hover {
  color: rgb(59 130 245);
}

.checkbox:checked:before {
  background-color: green;
}

.default-btn {

  color: #ffffff;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px !important;
  box-shadow: none;
  box-sizing: border-box;
  /* transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  font-family: 'Inter', sans-serif;
  /* font-weight: 500; */
  line-height: 1.75;
  /* background-color: #ffc544;
  border-radius: 30px; */
  /* background: linear-gradient(266.44deg,#28edec,#0564d7); */
  /* border-radius: 24px 0px; */
  background: linear-gradient(180deg,#449eff,#0029e6);
border-radius: 10px;
font-weight: 600 !important;
}
.default-btn2 {

  display: inline-flex !important;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;

  color: #ffffff;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 180px !important;
  box-shadow: none;
  box-sizing: border-box;
  /* transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  /* background-color: #ffc544;
  border-radius: 30px; */
  background: #449eff !important;
  justify-content: center;
  border-radius: 30px;
  min-height: 48px;
  text-decoration: none;
}
.default-btnhome {
  display: inline-flex !important;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;

  color: #ffffff;
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px !important;
  box-shadow: none;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 10px;

  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  background: linear-gradient(180deg,#449eff,#0029e6) !important;
  text-decoration: none;
}

.default-btn:hover {
  opacity: 0.8;
  
  color: #fcfcfc !important;
}
.default-btn2:hover {
  color: #fcfcfc !important;
}
.default-btnhome:hover {
  opacity: 0.8;
  color: #fcfcfc !important;
}
.availablebtnbg{
  background: rgb(59 130 245);
 
}
.availablebtnbg:hover{
  background: rgb(59 130 245);
 
}

.default-btn:active {
  /* background: #ffc851; */
  background: linear-gradient(266.44deg,#28edec,#0564d7);
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  overflow: hidden;
 
}

.default-btn:active::after {
  opacity: 0.239;
  content: " ";
  width: 52px;
  height: 52px;
  position: absolute;
  left: calc(50% - 26px);
  top: -8px;
  background: white;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
}

.default-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

.default-container {
  max-width: 1264px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  margin-top: 50px;
}

.secondary-btn {
  background: #03d1cf;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter', Consolas;
  font-weight: normal;
  letter-spacing: 0px;
  color: black;
  padding-left: 16px;
  padding-right: 16px;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 36px;
  border: none;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
}

.secondary-btn:hover {
  background: #3fdad9;
}

.secondary-btn:focus {
  background: #54dedd;
  border: 1px solid #1cb5b4fc;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

.secondary-btn:active {
  background: #54dedd;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  /* padding: 9px 16px; */
}

.secondary-btn:active::after {
  opacity: 0.239;
  content: " ";
  width: 52px;
  height: 52px;
  position: absolute;
  left: calc(50% - 26px);
  top: -8px;
  background: white;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 500ms linear;
}

.secondary-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

@keyframes ripple {
  to {
    transform: scale(2);
    opacity: 0.4;
  }
}

button.btn-link {
  margin-top: 1rem;
  background: none;
  color: white;
  border: none;
  padding: 0;
  font-size: 1rem;
  display: block;
  text-align: left;
}

.logout-icon {
  vertical-align: middle;
  font-size: 1.1rem;
  margin-top: -0.2rem;
  margin-right: 0.35rem;
  margin-left: -0.3rem;
  opacity: 0.8;
}

button.btn-link:hover {
  text-decoration: underline;
}

button.btn-link:hover .logout-icon {
  opacity: 1;
}

.page-container {
  padding-top: 4rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  /* padding-bottom: calc(3rem + 200px); */
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.default-cursor.App-cta {
  cursor: default;
}

/* NFTS NEW */

.header-dropdown {
  display: flex!important;
  align-items: center!important;
  position: relative!important;
  cursor: pointer;
}

.dropbtn {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0a1c4;
  border: none;
}

.header-dropdown-content {
  display: none;
  position: absolute;
  top: 35px;
  z-index: 1;
  min-width: 170px;
  background: #1b1e29;
  /* box-shadow: 0 4px 12px #262200; */
  border-radius: 12px;
  padding: 12px;
}

.header-dropdown:hover .header-dropdown-content {
  display: block;
}

.header-dropdown-content a {
  padding: 8px!important;
}

.recharts-wrapper{
  min-width: 210px;
}

.Footer{
  margin-top: auto;
}