a.btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.btn-center {
  justify-content: center;
}
.btn-left {
  justify-content: start;
}
.btn {
  display: inline-flex;
  align-items: center;
  border: none;
  color: white;
  font-size: 14px;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-sm {
  padding: 5px 14px;
}

.btn-lg,
.btn-xl {
  padding: 0.5rem 1rem;
}

.btn-sm .btn-label {
  font-weight: 400;
  font-size: 14px;
  margin-left: 8px;
  letter-spacing: 0;
}
.btn-lg .btn-label {
  margin-left: 0.75rem;
  font-size: 1.1875rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: 0;
}
.btn-xl .btn-label {
  margin-left: 0.75rem;
  font-size: 1.1875rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: 0;
}

.btn-sm .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.btn-lg .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
}
.btn-xl .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
}

.btn-primary {
  background: transparent;
  border: 1px solid var(--dark-blue-border);
  border-radius: var(--border-radius-sm);
}

.btn-primary:hover {
  background: #00052a;
  border: 1px solid #00052a;
}

.App-header-user .btn-primary:active {
  /* background: linear-gradient(266.44deg,#28edec,#0564d7) !important;
  border-color: #28edec !important; */
}

.App-header-user .btn-check:focus+.btn-primary,
.App-header-user .btn-primary:focus {
  /* background: linear-gradient(266.44deg,#28edec,#0564d7) !important;
  border-color: #28edec !important;
  box-shadow: 0 0 0 0.25rem #0564d7; */
}

@media (max-width: 450px) {
  .connect-wallet {
    padding: 5px 8px;
  }
}
@media (max-width: 350px) {
  .connect-wallet .btn-image {
    display: none;
  }
  .connect-wallet .btn-label {
    margin-left: 0px;
  }
  .connect-wallet {
    height: 36px;
  }
}
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #0564d7;
}
