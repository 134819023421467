.BlpSwap-content {
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Tab-title-section {
  padding: 2rem 1rem 0;
  margin-bottom: 24px;
}

.Tab-title {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 2.2rem;
  font-weight: 500;
  font-family: 'Inter', Consolas;
}

.Tab-description {
  letter-spacing: 0px;
  color: #a9a9b0;
  margin-top: 0.5rem;
}

.Tab-description span {
  color: #f89191;
}

.BlpSwap-from-token .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.BlpSwap-from-token .Modal .Modal-content {
  width: 27rem;
}

.BlpSwap-from-token .Modal-content {
  position: absolute;
  width: 25rem;
  max-height: 100vh;
  overflow: hidden;
  border: none;
  top: 0;
  bottom: 0;
}
.BlpSwap-from-token .Modal-content .Modal-body {
  padding-right: 5px;
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
}

.BlpSwap-content .BlpSwap-stats-card,
.BlpSwap-content .BlpSwap-box {
  width: 100%;
}

.BlpSwap-content .BlpSwap-stats-card {
  max-width: 45.935rem;
  margin-right: 1rem;
}

.BlpSwap-stats-card .App-card-row .label {
  color: #a9a9b0;
  opacity: 1;
}

.BlpSwap-content .BlpSwap-box {
  max-width: 30.935rem;
  border-radius: 15px;
  padding: 1rem;
  padding-top: 0;
}

.BlpSwap-box .Exchange-swap-option-tabs {
  margin: 1rem 0;
}

.App-card-title-mark-icon {
  position: relative;
  margin-right: 0.5rem;
  display: flex;
}

.App-card-title-mark-icon .selected-network-symbol {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #000526;
  border-radius: 50%;
}

.App-card-title-mark {
  display: flex;
}

.App-card-title-mark .App-card-title-mark-title {
  font-size: 1.032rem;
  line-height: 1.355rem;
  letter-spacing: 0px;
  color: #ffffff;
}

.App-card-title-mark .App-card-title-mark-subtitle {
  font-size: 0.774rem;
  line-height: 1rem;
  letter-spacing: 0px;
  color: #fff;
}

.BlpSwap-token-list {
  /* border: 1px solid #000526;
  border-radius: 15px;
  background: #000526; */
  /* border: 1px solid rgb(28 36 75); */
  border-radius: 20px;
  background: #1b1e29;
  padding: 1rem;
  padding-top: 0;
  text-align: left;
  margin-bottom: 70px;
}

.BlpSwap-token-list-content {
  display: grid;
  padding: 1rem;
  padding-top: 0;
  text-align: left;
}

.BlpSwap-box .App-card-title {
  margin-bottom: 0.5rem;
}

.BlpSwap-box .Exchange-info-row {
  height: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BlpSwap-box .Exchange-info-row .Exchange-info-label {
  margin-right: 0.5rem;
  color: #a9a9b0;
  opacity: 1;
}

.BlpSwap-cta {
  margin: 0 auto;
  text-align: center;
  padding-top: 0;
}

.BlpSwap-cta .Exchange-swap-button {
  margin: 0 auto;
  justify-content: center;
  min-height: 3.097rem;
  font-size: 1rem;
}

.token-table {
  margin-left: -1rem;
  margin-right: -1rem;
  width: calc(100% + 2rem);
  border-spacing: 0;
  white-space: nowrap;
}

.token-grid {
  display: none;
}

.token-table td,
.token-table th {
  padding: 0.75rem;
}

.token-table td:nth-child(2),
.token-table th:nth-child(2) {
  width: 110px;
}

.token-table td:nth-child(3),
.token-table th:nth-child(3) {
  width: 320px;
}

.token-table td:nth-child(4),
.token-table th:nth-child(4) {
  width: 200px;
}

.token-table td:nth-child(5),
.token-table th:nth-child(5) {
  width: 130px;
}

.token-table td {
  letter-spacing: 0.25px;
  color: #ffffffde;
}

.token-table th:first-child,
.token-table td:first-child {
  padding-left: 1rem;
  width: 17rem;
}

.token-table th:last-child,
.token-table td:last-child {
  padding-right: 1rem;
  text-align: right;
  width: 170px;
}

.token-table th {
  font-weight: normal;
  letter-spacing: 0.25px;
  color: #a9a9b0;
  text-transform: uppercase;
}

.token-table .action-btn {
  width: 100%;
  justify-content: center;
}
.cap-reached {
  margin-left: 8px;
}

@media (max-width: 900px) {
  .BlpSwap-content {
    flex-wrap: wrap;
  }

  .BlpSwap-content .BlpSwap-stats-card {
    margin: 0;
    margin-bottom: 2rem;
    max-width: 100%;
  }

  .BlpSwap-content .BlpSwap-box {
    margin: 0;
    max-width: 100%;
  }
}

@media (max-width: 1100px) {
  .BlpSwap-from-token .Modal {
    align-items: center;
    justify-content: center;
  }
  .BlpSwap-from-token .Modal .Modal-content {
    width: 27rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }
  .BlpSwap-from-token .Modal .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(80vh - 5.5rem);
    padding-right: 5px;
    margin: 1rem calc(1rem - 5px) 1rem 1rem;
  }

  .BlpSwap-token-list {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .BlpSwap-token-list-content {
    padding: 0;
  }

  .token-table {
    display: none;
  }

  .token-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin-top: 0.5rem;
  }
}
@media (max-width: 510px) {
  .token-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-top: 0.5rem;
  }
}
