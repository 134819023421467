.BuyBASE {
    /*background: #101124;*/
    min-height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.BuyBASE-container {
    padding: 3rem 0 2rem 0;
    text-align: center;
    max-width: 70rem!important;
}

.BuyBASE-title {
    font-size: 2.2rem;
    line-height: 3.097rem;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-bottom: 1.5484rem;
    font-family: 'Inter', Consolas, sans-serif;
    font-weight: 600;
    /*font-family: 'Relative Bold';*/
}

.BuyBASE-description {
    /* font-size: 1.161rem; */
    line-height: 1.484rem;
    letter-spacing: 0px;
    color: #fff;
}

.better-rates-description {
    margin-top: 1.548rem;
    letter-spacing: 0;
}

.BuyBASE-description a {
    text-decoration: none;
    position: relative;
}

.alternative-bridges {
    margin: 1rem auto;
    max-width: 728px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.alternative-bridges a {
    display: flex;
    position: relative;
}

.alternative-bridges a.Synapse:hover::before,
.alternative-bridges a.Synapse:focus::before,
.alternative-bridges a.Multichain:hover::before,
.alternative-bridges a.Multichain:focus::before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.textcolorwhite:hover{
    color: #fff;
}
.textcolorwhite:after{
    color: #fff;
}
.textcolorwhite{
    color: #fff;
}

.BuyBASE-purchase-block {
    background-image: url('../../img/btn_big_purchasemvx.svg');
    padding-top: 2.1897rem;
    padding-left: 2.826rem;
    max-width: 397px;
    width: 100%;
    min-height: 120px;
    margin: 1.548rem auto;
    margin-bottom: 3rem;
    display: flex;
    box-shadow: 0px 9px 12px #29282854;
    /* border: 1px solid #000526; */
    border-radius: 4px;
    text-decoration: none;
    position: relative;
}

.BuyBASE-purchase-block:hover::before {
    content: " ";
    position: absolute;
    background: #bfbfbe41;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.BuyBASE-purchase-block:active::before {
    content: " ";
    position: absolute;
    background: #bfbfbe41;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.BuyBASE-purchase-block-info {
    margin-left: 2.461rem;
    text-align: left;
}

.BuyBASE-purchase-block-info__title {
    font-size: 1.677rem;
    line-height: 2.129rem;
    letter-spacing: 0px;
    color: #080b27;
}

.BuyBASE-purchase-block-info__subtitle {
    color: #080b27;
    font-size: 1.161rem;
    line-height: 1.484rem;
    letter-spacing: 0px;
}

.BuyBASE-title-section .BLP-block-section {
    display: flex;
    max-width: 416px;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 1.548rem;
}

.BLP-block-section .BLP-block {
    background-image: url('../../img/btn_big_purchasemvx.svg');
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 60px;
    border-radius: 4px;
    text-decoration: none;
    position: relative;
}

.BLP-block-section .BLP-block:hover::before {
    content: " ";
    position: absolute;
    background: #bfbfbe41;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.BLP-block-section .BLP-block:active::before {
    content: " ";
    position: absolute;
    background: #bfbfbe41;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.BLP-block .BLP-block-icon {
    display: flex;
}

.BLP-block .BLP-block-label {
    color: #080b27;
    font-size: 1.161rem;
    line-height: 1.484rem;
    margin-left: 0.516rem;
}

@media (max-width: 900px) {
    .alternative-bridges {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .alternative-bridges a + a {
        margin-top: 1.548rem;
    }

    .BuyBASE-purchase-block {
        padding-left: 1.859rem;
    }

    .BuyBASE-title-section .BLP-block-section {
        flex-direction: column;
        align-items: center;
    }

    .BLP-block-section .BLP-block + .BLP-block {
        margin-top: 16px;
    }
}

@media (max-width: 400px) {
    .BuyBASE-container {
        padding-left: 16px!important;
        padding-right: 16px!important;
    }

    .BuyBASE-description {
        font-size: 1rem;
        line-height: 1.3rem;
    }

    .BuyBASE-purchase-block {
        padding-left: 1.5rem;
    }

    .BuyBASE-purchase-block-info {
        margin-left: 1.5rem;
        text-align: left;
    }

    .BuyBASE-purchase-block-info__title {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .BuyBASE-purchase-block-info__subtitle {
        font-size: 1rem;
        line-height: 1.3rem;
    }
}
.marginbottombybase{
    margin-bottom: 70px;
}
.BuyBASE-container-pdsmallscreen{
    padding-left: 16px!important;
    padding-right: 16px!important;
}
